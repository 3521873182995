import { createPopper as _createPopper } from '@popperjs/core/lib/popper-lite'
import flip from '@popperjs/core/lib/modifiers/flip'
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow'
import { merge } from 'lodash-es'
import type { Options } from '@popperjs/core/lib/types'

const modifiers = [flip, preventOverflow]

export default function popper() {
    let popperInstance: ReturnType<typeof _createPopper> | null = null

    const createPopper = (
        trigger: HTMLElement,
        popper: HTMLElement,
        options?: Partial<Options>,
    ) => {
        if (!trigger) console.error('[usePopper] Trigger element not found')
        if (!popper) console.error('[usePopper] Popper element not found')
        if (popperInstance) destroyPopper()

        popperInstance = _createPopper(
            trigger,
            popper,
            merge({ modifiers }, options),
        )

        return popperInstance
    }

    const destroyPopper = () => popperInstance?.destroy()

    return { createPopper, destroyPopper }
}
